import React, { useState, useEffect } from "react";

const SellerInfo = ({ car }) => {
    const convertToShortDate=(date)=>{
        const newDate =new Date(date);
        const options = {
      year:'numeric',month:'long',day:'numeric'
        }
        return newDate.toLocaleDateString("nl-BE",options).toUpperCase();
      }
  return (
    <div className="col-xl-12">
      <div className="wagen-title mb-2">Klant informatie</div>
      <div className="feature-box">
        <ul className="feature-list row">
          <li className="list-group-item col-md-4 col-sm-6 col-12">
            <div className="row">
              <div className="col-4 wagen-detail-item">NAAM</div>
              <div className="col-8">{car.name}</div>
            </div>
          </li>
          <li className="list-group-item col-md-4 col-sm-6 col-12">
            <div className="row">
              <div className="col-4 wagen-detail-item">EMAIL</div>
              <div className="col-8">{car.email}</div>
            </div>
          </li>
          <li className="list-group-item col-md-4 col-sm-6 col-12">
            <div className="row">
              <div className="col-5 wagen-detail-item">TELEFOON</div>
              <div className="col-7">{car.telephone}</div>
            </div>
          </li>
          <li className="list-group-item col-md-4 col-sm-6 col-12">
            <div className="row">
              <div className="col-5 wagen-detail-item">POSTCODE</div>
              <div className="col-7">{car.postCode}</div>
            </div>
          </li>
          <li className="list-group-item col-md-4 col-sm-6 col-12">
            <div className="row">
              <div className="col-5 wagen-detail-item">ONTVANGEN DATUM</div>
              <div className="col-7">{convertToShortDate(car.receivedDate)}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default SellerInfo;
