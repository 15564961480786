import React, {useState,useEffect}from "react";
import axios from "axios";

import SellCarList from "./SellCarList";

const  CarForSale=()=>{
    const [cars, setCars] = useState([]);

    const getCarsList = () => {
      const token=window.localStorage.getItem('token')||null;
      axios.get(process.env.REACT_APP_API_URL+"/SellYourCar", {
        headers: {
          "Content-Type": "application/json",
              "Authorization": "Bearer " + token 
        }
      }).then(function(response) {
        var car=  response.data.map(car=>{ car.imageUrls.sort((a, b) =>a.localeCompare(b)); return car;});
        setCars(car);
      });
    };
    useEffect(() => {
      getCarsList();
    }, []);
    const onDeleteCar=(id)=>{
      const token=window.localStorage.getItem('token')||null;
      axios.delete(process.env.REACT_APP_API_URL+"/SellYourCar/"+id, {
            headers: {
                "Authorization": "Bearer " + token,
            }
          }).then(function(response) {
            getCarsList();
        });
    }
    const onDraftChecked=(index,value)=>{
      let car = cars[index];
      const params = {
        draft: value,
        id: car.id,
      };
      const token=window.localStorage.getItem('token')||null;
      axios
        .put(process.env.REACT_APP_API_URL+"/SellYourCar/SetDraft", params, {
          headers: {
            "Content-Type": "application/json",
                "Authorization": "Bearer " + token 
          }
        })
        .then(function(response) {
          let newArr = [...cars];
          newArr[index].draft = value;
          setCars(newArr);
        });
    };
    return  (<SellCarList
    cars={cars}
    onDraftChecked={onDraftChecked}
    DeleteCar={onDeleteCar}
  />)


}
export default CarForSale;