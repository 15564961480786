import React, { useState, useEffect,useCallback } from "react";
import axios from "axios";
import { useParams,Link,useHistory } from "react-router-dom";
import ImageGallary from "../../../../common/imageGallary.jsx";
import Loading from "../../../../common/Loading.jsx";
import SellerInfo from "../CarForSale/SellerInfo";
import SellCarInfo from "../CarForSale/SellCarInfo";

import DeleteIcon from "../../../../assets/image/icons/delete.svg";
import ViewIcon from "../../../../assets/image/icons/eye.png";
const SellCar = () => {
  const [car, setCar] = useState({});
  const [imageUrls, setImageUrls] = useState([]);
  const history = useHistory();
  const goBack = useCallback(() => history.push('/Car-for-sale'), [history]);
  let { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      const token = window.localStorage.getItem("token") || null;
      const carResult = await axios(
        process.env.REACT_APP_API_URL + "/SellYourCar/" + id,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        },
      );
      var car = carResult.data;
      if(car){
        setVisited(car.id,true);
      }
      setCar(car);
     
      setImageUrls(car.imageUrls);
    };
    fetchData();
  }, []);
const setVisited=(id,value)=>{
    const params = {
        id: id,
        visited:value
      };
      const token = window.localStorage.getItem("token") || null;
    axios.put( process.env.REACT_APP_API_URL + "/SellYourCar", params,{
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }).then(function(response) {
       
      })
}
const onDelete=()=>{
id=car.id;
    const token=window.localStorage.getItem('token')||null;
    axios.delete(process.env.REACT_APP_API_URL+"/SellYourCar/"+id, {
          headers: {
              "Authorization": "Bearer " + token,
          }
        }).then(function(response) {
            goBack();
      });

}
  if (Object.keys(car).length === 0 && car.constructor === Object) {
    return <Loading />;
  } else {
    return (
      <div className="container">
        <div className="row">
            <div className="col-12 d-flex">
                <Link to={`/Car-for-sale`}>Back</Link>
               <button className="btn btn-success ml-auto" onClick={()=>setVisited(car.id,false)}><span className="mr-2">Niet bezocht</span><img src={ViewIcon}/></button>
               <button className="btn btn-primary ml-2"onClick={onDelete}><span className="mr-2">Delete</span> <img src={DeleteIcon}/></button>
            </div>
          <div className="col-xl-9 col-lg-9 col-md-12">
            <div className="row">
              <ImageGallary urls={imageUrls} />
              <SellCarInfo car={car} />
              <SellerInfo car={car} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default SellCar;
