import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Stock from "./Stock/index.jsx";
import NewCar from "./Stock/newCar.jsx";
import DashboardChildren from "./Dashboard/index.jsx";

import "../../../assets/css/admin.css";
import "../../../assets/css/style.css";
import RadoCarsLogo from "../../../assets/image/logo.svg";
import EditCar from "./Stock/editCar.jsx";
import DraftList from "./Stock/draftList.jsx";
import CarForSale from "./CarForSale/index.jsx";
import SellCar from "./CarForSale/SellCar"

const routes = [
  {
    path: "/",
    exact: true,
    main: () => <DashboardChildren />,
  },
  {
    path: "/stock",
    main: () => <Stock />,
  },
  {
    path: "/NewCar",
    main: () => <NewCar/>,
  },
  {
    path: "/Edit/:id",

    main:() => <EditCar/>
  },
  {
    path: "/Draft",

    main: () => <DraftList/>,
  },  {
    path: "/Car-for-sale",

    main: () => <CarForSale/>,
  },
  {
    path: "/Tekoop-auto/:id",

    main: () => <SellCar/>,
  },
];
const Dashboard = () => {
  return (
    <Router>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-2 admin-left-side mb-0">
            <div className="d-flex justify-content-center mt-2 mb-5">
              <img src={RadoCarsLogo} />
            </div>
            <ul className="list-group admin-list-group ">
            <Link  className="menu-item" to="/"><li className="list-group-item list-group-item-action ">
              Dashboard
              </li></Link>
              <Link className="menu-item" to="/stock">
              <li className="list-group-item list-group-item-action ">
                Stock
              </li></Link>
              <Link className="menu-item"  to="/NewCar"><li className="list-group-item list-group-item-action ">
                New car
              </li></Link>
              <Link className="menu-item"  to="/Draft"><li className="list-group-item list-group-item-action ">
               Draft
              </li></Link>
              <Link className="menu-item"  to="/Car-for-sale/"><li className="list-group-item list-group-item-action ">
              Car for sale
              </li></Link>
              <Link className="menu-item"  to="/"><li className="list-group-item list-group-item-action ">
                Dashboard
              </li></Link>
            </ul>
          </div>
          <div className="col-xl-10 admin-right-side">
            <div className="row">
              <div className="col-xl-12">
                <h1>menu header</h1>
              </div>
            </div>
            <Switch>
              {routes.map((route, index) => (
                // Render more <Route>s with the same paths as
                // above, but different components this time.
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
};
export default Dashboard;
