import React, { useState, useEffect } from "react";

const SellCarInfo = ({ car }) => {
    return(<div className="col-xl-12">
    <div className="wagen-title mb-2">Algemene informatie</div>
    <div className="feature-box">
      <ul className="feature-list row">
        <li className="list-group-item col-md-4 col-sm-6 col-12">
          <div className="row">
            <div className="col-8 wagen-detail-item">MERK</div>
            <div className="col-4">{car.mark}</div>
          </div>
        </li>
        <li className="list-group-item col-md-4 col-sm-6 col-12">
          <div className="row">
            <div className="col-8 wagen-detail-item">MODEL</div>
            <div className="col-4">{car.model}</div>
          </div>
        </li>
        <li className="list-group-item col-md-4 col-sm-6 col-12">
          <div className="row">
            <div className="col-8 wagen-detail-item">
              KILOMETERSTAND
            </div>
            <div className="col-4">{car.kilometer}</div>
          </div>
        </li>
        <li className="list-group-item col-md-4 col-sm-6 col-12">
          <div className="row">
            <div className="col-8 wagen-detail-item">BOUWJAAR</div>
            <div className="col-4">{car.year}</div>
          </div>
        </li>
        <li className="list-group-item col-md-4 col-sm-6 col-12">
          <div className="row">
            <div className="col-8 wagen-detail-item">
              TRANSMISSIE
            </div>
            <div className="col-4">{car.gear}</div>
          </div>
        </li>
        <li className="list-group-item col-md-4 col-sm-6 col-12">
          <div className="row">
            <div className="col-8 wagen-detail-item">EURONORM</div>
            <div className="col-4">{car.euroNorm}</div>
          </div>
        </li>
        <li className="list-group-item col-md-4 col-sm-6 col-12">
          <div className="row">
            <div className="col-8 wagen-detail-item">BRANDSTOF</div>
            <div className="col-4">{car.fuel}</div>
          </div>
        </li>

        <li className="list-group-item col-md-4 col-sm-6 col-12">
          <div className="row">
            <div className="col-8 wagen-detail-item"></div>
            <div className="col-4"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>)
}
export default SellCarInfo;