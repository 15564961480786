import React, {useState,useEffect} from "react";
import axios from "axios";
import DeleteIcon from "../../../../assets/image/icons/delete.svg";
const DraftList=()=>{
    const [draftCarsList, setDraftCarsList] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(-1);
    
    useEffect(() => {
        getDraftCarsList();
    }, [])
    const oddRow = (value) => {
        if (value % 2 === 0) return false;
        return true;
      };
      
      const getDraftCarsList = () => {
        const token=window.localStorage.getItem('token')||null;
        axios.get(process.env.REACT_APP_API_URL+"/car/Draft", {
          headers: {
            "Content-Type": "application/json",
                "Authorization": "Bearer " + token 
          }
        }).then(function(response) {
          var car=  response.data.map(car=>{ car.imageUrls.sort((a, b) =>a.localeCompare(b)); 
            car.select=false;return car;});
         
          setDraftCarsList(car);
        });
      };
const onSelectCar=(index)=>{
    var carsList=[...draftCarsList];
    carsList[index].select=!carsList[index].select;
    setDraftCarsList(carsList);
}
const onDeleteSelected=()=>{
const carsId=[];
draftCarsList.map(element=>{
    if(element.select){
        carsId.push(element.id);
    }
})
const token=window.localStorage.getItem('token')||null;
const formData = new FormData();
for (var i = 0; i < carsId.length; i++) {
    formData.append("carsId", carsId[i]);
  }
axios
.post(process.env.REACT_APP_API_URL+"/car/Delete", formData, {
  headers: {
    "Content-Type": "multipart/form-data",
    "Authorization": "Bearer " + token,
    Accept: "application/json"
    
  },
}).then(function(response) {
    getDraftCarsList();
});
}
const onDeleteCar=(id)=>{
    const token=window.localStorage.getItem('token')||null;
  axios.delete(process.env.REACT_APP_API_URL+"/car/"+id, {
        headers: {
            "Authorization": "Bearer " + token,
        }
      }).then(function(response) {
        getDraftCarsList();
    });
}
const onSelectAll=(flag)=>{
    setSelectAll(flag);
    var newCarlist=draftCarsList;
    for(var i=0; i<newCarlist.length;i++){
        newCarlist[i].select=flag;
    }
}
return (<div className="container car-list">
    <div className="d-flex justify-content-end">
        <button className="btn btn-danger">
        <img src={DeleteIcon} onClick={()=>onDeleteSelected()}/>
        </button>
    </div>
<table className="car-list-table">
  <thead>
    <tr className="car-list-header">
    <th className="pl-2 pr-2">
            <input type="checkbox" checked={selectAll} onChange={(e) =>onSelectAll(e.target.checked)}/>
          </th>
      <th className="pl-2 pr-2">Titel</th>
      <th className="pl-2 pr-2">Foto</th>
      <th className="pl-2 pr-2">Prijs</th>
      <th className="pl-2 pr-2">Bouwjaar</th>
      <th className="pl-2 pr-2">Kilometerstand</th>
      <th className="pr-2">Brandstof</th>
      <th className="pl-2 pr-2">Euronorm</th>
      <th className="pl-2 pr-2"></th>
      <th className="pl-2 pr-2"></th>
    </tr>
  </thead>
  <tbody>
    {draftCarsList.map((car, index) => {
      return (
        <tr className={oddRow(index) ? "odd-row" : ""}key={index}>
           <th className="pl-2 pr-2">
            <input type="checkbox" checked={car.select} key={index} onChange={()=>onSelectCar(index)}/>
          </th>
          <td className="pl-2 pr-2 text-wrap"style={{color:'blue',width:'12%'}}>{car.title}</td>
          <td className="pl-2 pr-2">
            <img
              src={car.imageUrls[0]}
              style={{ width: "100px" }}
            />
          </td>
          <td className="pl-2 pr-2"style={{color:'red'}}>{car.price}</td>
          <td className="pl-2 pr-2">{car.year}</td>
          <td className="pl-2 pr-2">{car.kilometer}</td>
          <td className="pl-2 pr-2">{car.fuel}</td>
          <td className="pl-2 pr-2">{car.euroNorm}</td>
          
          
          <td className="pl-1 pr-1">
          {
              deleteIndex==index?
              (
              <div className="d-flex flex-column text-center">
                  Ben je zeker?
                  <div className="d-flex flex-row justify-content-center p-2 p-2">
                      <button className="btn btn-secondary mr-2"onClick={()=>setDeleteIndex(-1)}>Neen
                      </button>
                  <button className="btn btn-danger" onClick={()=>onDeleteCar(car.id)}>Ja</button>
                  </div>
              </div>
              
              )
              
              :<img src={DeleteIcon} onClick={()=>setDeleteIndex(index)}/>
              }
           
          </td> 
        </tr>
      );
    })}
  </tbody>
</table>
</div>   )
}
export default DraftList;