import React, { useState, useEffect } from "react";
import CarPhoto from "./carPhoto.jsx";
import axios from "axios";
import CarAttributes from "./carAttributes.jsx";
import CarFeatures from "./carFeatures.jsx";
import Alert from "../../../../common/Alert.jsx";

const CarForm = ({ type, carId }) => {
  const [attributes, setAttributes] = useState({
    marks: [],
    models: [],
    fuels: [],
    gears: [],
    euroNorms: [],
    colors: [],
  });
  const [euroNorms, setEuroNorms] = useState([]);
  const [models, setModels] = useState([]);
  const [showAlert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState("");
  const [selectedAttributes, setselectedAttributes] = useState({
    mark: {},
    model: {},
    gear: {},
    fuel: {},
    euroNorm: {},
    price: "",
    seats: "",
    innerColor: {},
    color: {},
    year: "",
    kilometer: "",
    PK: "",
    cylinderCapacity: "",
  });

  const [features, setFeatures] = useState([]);
  const [title, settitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [featured, setFeatured] = useState(false);
  const [removeImages, setRemoveImages] = useState(false);

  const [imageUrls, setImageUrls] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const token = window.localStorage.getItem("token") || null;
      const result = await axios(
        process.env.REACT_APP_API_URL + "/CarAttributes",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        },
      );
      var attributes = {
        marks: result.data.marks,
        models: result.data.models,
        fuels: result.data.fuels,
        gears: result.data.gears,
        euroNorms: result.data.euroNorms,
        colors: result.data.colors,
      };
      setEuroNorms(result.data.euroNorms);
      setAttributes(attributes);
      setFeatures(result.data.features);
      if (type != "new") {
        const carResult = await axios(
          process.env.REACT_APP_API_URL + "/car/" + carId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          },
        );
        carResult.data.imageUrls.sort((a, b) => a.localeCompare(b));
        var car = carResult.data;

        var markId = attributes.models.find((model) => model.id == car.model)
          .markId;
        var models = attributes.models
          .filter((model) => model.markId == markId)
          .map((model) => ({
            label: model.name,
            value: model.id,
          }));
        var carAttributes = {
          mark: attributes.marks.find((mark) => mark.value == markId),
          model: models.find((model) => model.value === car.model),
          gear: attributes.gears.find((gear) => gear.label == car.gear),
          fuel: attributes.fuels.find((fuel) => fuel.label == car.fuel),
          euroNorm: attributes.euroNorms.find(
            (euroNorm) => euroNorm.label == car.euroNorm,
          ),
          price: car.price,
          seats: car.seats,
          innerColor: attributes.colors.find(
            (color) => color.label == car.color,
          ),
          color: attributes.colors.find((color) => color.label == car.color),
          year: car.year,
          kilometer: car.kilometer,
          PK: car.pk,
          cylinderCapacity: car.cylinderCapacity,
        };
        setImageUrls(car.imageUrls);
        settitle(car.title);
        setDescription(car.description);
        setFeatured(car.special);
        setModels(models);
        setselectedAttributes(carAttributes);
        setSelectedFeatures(car.carFeatures);
      }
    };
    fetchData();
  }, []);
  const handleTitleChange = (event) => {
    settitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const getModels = (markId) => {
    let newModels =
      attributes.models
        .filter((model) => model.markId == markId)
        .map((model) => ({
          label: model.name,
          value: model.id,
        })) || [];
    return newModels;
  };

  const handleMarkChange = (mark) => {
    let attributeArray = selectedAttributes;
    attributeArray.mark = mark;
    setselectedAttributes(attributeArray);
    var models = getModels(mark.value);
    setModels(models);
  };

  const handleModelChange = (value) => {
    setselectedAttributes({ ...selectedAttributes, model: value });
  };
  const handleGearChange = (value) => {
    setselectedAttributes({ ...selectedAttributes, gear: value });
  };
  const handleFuelChange = (value) => {
    setselectedAttributes({ ...selectedAttributes, fuel: value });
  };
  const handleEuroNormChange = (value) => {
    setselectedAttributes({ ...selectedAttributes, euroNorm: value });
  };
  const handleInnerColorChange = (value) => {
    setselectedAttributes({ ...selectedAttributes, innerColor: value });
  };
  const handleColorChange = (value) => {
    setselectedAttributes({ ...selectedAttributes, color: value });
  };
  const handlePriceChange = (e) => {
    setselectedAttributes({ ...selectedAttributes, price: e.target.value });
  };
  const handleSeatsChange = (e) => {
    setselectedAttributes({ ...selectedAttributes, seats: e.target.value });
  };
  const handleYearChange = (e) => {
    setselectedAttributes({ ...selectedAttributes, year: e.target.value });
  };
  const handlePKChange = (e) => {
    setselectedAttributes({ ...selectedAttributes, PK: e.target.value });
  };
  const handleCylinderCapacityChange = (e) => {
    setselectedAttributes({
      ...selectedAttributes,
      cylinderCapacity: e.target.value,
    });
  };
  const handleKilometerChange = (e) => {
    setselectedAttributes({
      ...selectedAttributes,
      kilometer: e.target.value,
    });
  };

  const updateSelectedFeatures = (value) => {
    let found = selectedFeatures.includes(value);
    if (!found) {
      setSelectedFeatures([...selectedFeatures, value]);
    } else {
      let array = selectedFeatures;
      let newArray = array.filter((item) => {
        return item != value;
      });

      setSelectedFeatures(newArray);
    }
  };
  const handleFeaturedChange = (event) => {
    setFeatured(event.target.checked);
  };
  const clearAll = () => {
    const attribute = {
      mark: {},
      model: {},
      gear: {},
      fuel: {},
      euroNorm: {},
      price: "",
      seats: "",
      innerColor: {},
      color: {},
      year: "",
      kilometer: "",
      PK: "",
      cylinderCapacity: "",
    };
    setselectedAttributes(attribute);
    setSelectedFeatures([]);
    setFeatured(false);
    settitle("");
    setDescription("");
    setAlert(false);
    setAlertText("");
    setModels([]);
    setImageUrls([]);
    setImages([]);
    setRemoveImages(true);
  };
  const getCarImages = (files) => {
    setRemoveImages(false);
    setImages(files);
  };
  const checkEmptyFilds = () => {
    for (var key in selectedAttributes) {
      if (selectedAttributes[key] == null || selectedAttributes[key] == "")
        return true;
    }

    if (images.length == 0 && type == "new") return true;

    if (title == "") return true;

    return false;
  };

  const saveCar = () => {
    if (!checkEmptyFilds()) {
      const formData = new FormData();
      var files = images
        .sort((a, b) => a.order - b.order)
        .map(
          (file, index) =>
            new File(
              [file.image],
              `-${index > 8 ? index + 1 : "0" + (index + 1)}.jpg`,
            ),
        );

      for (var i = 0; i < files.length; i++) {
        formData.append("images", files[i]);
      }
      for (var i = 0; i < selectedFeatures.length; i++) {
        formData.append("Features", selectedFeatures[i]);
      }

      if (type == "Edit") formData.append("Id", carId);

      formData.append("Title", title);
      formData.append("Description", description);
      formData.append("ModelId", selectedAttributes.model.value);
      formData.append("Model", selectedAttributes.model.label);
      formData.append("MarkId", selectedAttributes.mark.value);
      formData.append("Gear", selectedAttributes.gear.value);
      formData.append("Fuel", selectedAttributes.fuel.value);
      formData.append("EuroNorm", selectedAttributes.euroNorm.label);
      formData.append("EuroNormId", selectedAttributes.euroNorm.value);
      formData.append("Price", selectedAttributes.price);
      formData.append("Seats", selectedAttributes.seats);
      formData.append("InnerColor", selectedAttributes.innerColor.value);
      formData.append("Color", selectedAttributes.color.value);
      formData.append("Year", selectedAttributes.year);
      formData.append("Kilometers", selectedAttributes.kilometer);
      formData.append("PK", selectedAttributes.PK);
      formData.append("CylinderCapacity", selectedAttributes.cylinderCapacity);
      formData.append("Special", featured);
      const token = window.localStorage.getItem("token") || null;
      if (type == "new") {
        window.scrollTo(0, 0);
        axios
          .post(process.env.REACT_APP_API_URL + "/car", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + token,
              Accept: "application/json",
            },
          })
          .then(function(response) {
            clearAll();
            setAlertType("success");
            setAlertText("Succesvol opgeslagen!");
            setAlert(true);
          })
          .catch(function(error) {
            clearAll();
            setAlertType("Error");
            setAlertText("Error:De auto is niet opgeslagen door een faut");
            setAlert(true);
            console.log(error);
          });
      } else {
        window.scrollTo(0, 0);
        axios
          .put(process.env.REACT_APP_API_URL + "/car/Update", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + token,
              Accept: "application/json",
            },
          })
          .then(function(response) {
            setAlertType("success");
            setAlertText("De wijziging is Succesvol opgeslagen!");
            setAlert(true);
          })
          .catch(function(error) {
            setAlertType("Error");
            setAlertText("Error:De auto is niet gewijzigd door een faut");
            setAlert(true);
            console.log(error);
          });
      }
    }
  };

  const handleModelCreate = (newValue) => {
    var newElement = {
      label: newValue,
      value: -1,
    };
    if (Object.keys(selectedAttributes.mark).length > 0) {
      setModels([...models, newElement]);
    }
  };
  const handleEuroNormCreate = (newValue) => {
    var newElement = {
      value: -1,
      label: newValue,
    };
    setEuroNorms([...euroNorms, newElement]);
  };
  const closeAlert = () => {
    setAlert(false);
    setAlertText("");
    setAlertType("");
  };
  return (
    <div className="Container">
      {showAlert && (
        <Alert
          show={showAlert}
          text={alertText}
          type={alertType}
          close={closeAlert}
        />
      )}
      <div className="row">
        <div className="col-xl-12">
          <div className="form-group  row-content">
            <label className="w-100 content-title">TITEL</label>
            <input
              type="text"
              className="form-control"
              onChange={handleTitleChange}
              value={title}
            />
          </div>
          <div className="form-group  row-content">
            <label className="w-100 content-title">BESCHRIJVING</label>
            <textarea
              type="text"
              row="90"
              className="form-control"
              onChange={handleDescriptionChange}
              value={description}
            />
          </div>
        </div>
      </div>
      <CarPhoto
        removeImages={removeImages}
        getCarImages={getCarImages}
        imageUrls={imageUrls}
      />
      <div className="row">
        <div className="col-xl-12">
          <div className="form-group  row-content">
            <label className="w-100 content-title">KENMERKEN</label>
            <CarAttributes
              selectedAttributes={selectedAttributes}
              attributes={attributes}
              euroNorms={euroNorms}
              models={models}
              handleMarkChange={handleMarkChange}
              handleModelChange={handleModelChange}
              handleGearChange={handleGearChange}
              handleFuelChange={handleFuelChange}
              handleEuroNormChange={handleEuroNormChange}
              handleInnerColorChange={handleInnerColorChange}
              handleColorChange={handleColorChange}
              handlePriceChange={handlePriceChange}
              handleSeatsChange={handleSeatsChange}
              handleYearChange={handleYearChange}
              handlePKChange={handlePKChange}
              handleCylinderCapacityChange={handleCylinderCapacityChange}
              handleKilometerChange={handleKilometerChange}
              handleModelCreate={handleModelCreate}
              handleEuroNormCreate={handleEuroNormCreate}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="form-group  row-content">
            <label className="w-100 content-title">UITRUSTING EN OPTIES</label>
            <CarFeatures
              features={features}
              selectedFeatures={selectedFeatures}
              updateSelectedFeatures={updateSelectedFeatures}
            />
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-xl-3">
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              onChange={handleFeaturedChange}
              value={featured}
            />
            <label className="feature-label ">Show car in home page</label>
          </div>
        </div>
        <div className="col-xl-9 d-flex">
          <button
            className="btn btn-danger ml-auto mr-2"
            disabled={type == "new" ? false : true}
            onClick={clearAll}
          >
            Clear All
          </button>
          <button className="btn btn-success" onClick={saveCar}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
export default CarForm;
