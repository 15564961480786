import React from "react";
import Select from "react-select";
import CreatableSelect  from 'react-select/creatable';
const colourStyles = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "black" : null,
      cursor: isFocused ? "pointer" : null,
    };
  },
};
const CarAttributes = ({
  attributes,
  models,
  euroNorms,
  selectedAttributes,
  handleMarkChange,
  handleModelChange,
  handleGearChange,
  handleFuelChange,
  handleEuroNormChange,
  handleInnerColorChange,
  handleColorChange,
  handlePriceChange,
  handleSeatsChange,
  handleYearChange,
  handlePKChange,
  handleCylinderCapacityChange,
  handleKilometerChange,
  handleModelCreate,
  handleEuroNormCreate
  

}) => {
  
  return (
    <div className="row">
      <div className="col-xl-4 mb-3">
        <label>Merk</label>
        <Select
          value={selectedAttributes.mark}
          options={attributes.marks}
          className="select-rado-container"
          classNamePrefix="select-rado"
          styles={colourStyles}
          onChange={handleMarkChange}
        />
      </div>
      <div className="col-xl-4 mb-3">
        <label>Model</label>
        <CreatableSelect
         isClearable
          onCreateOption={handleModelCreate}
          value={selectedAttributes.model}
          options={models}
          className="select-rado-container "
          classNamePrefix="select-rado"
          styles={colourStyles}
          onChange={handleModelChange}
        />
      </div>
      <div className="col-xl-4">
        <label>Brandstof</label>
        <Select
          className="select-rado-container "
          classNamePrefix="select-rado"
          options={attributes.fuels}
          value={selectedAttributes.fuel}
          styles={colourStyles}
          onChange={handleFuelChange}
        />
      </div>
      <div className="col-xl-4 mb-3">
        <label>Transmissie</label>
        <Select
          className="select-rado-container"
          classNamePrefix="select-rado"
          options={attributes.gears}
          value={selectedAttributes.gear}
          styles={colourStyles}
          onChange={handleGearChange}
        />
      </div>
      <div className="col-xl-4 mb-3">
        <label>Euronorm </label>
        <CreatableSelect
         isClearable
         onCreateOption={handleEuroNormCreate}
          className="select-rado-container"
          classNamePrefix="select-rado"
          options={euroNorms}
          value={selectedAttributes.euroNorm}
          styles={colourStyles}
          onChange={handleEuroNormChange}
        />
      </div>
      <div className="col-xl-4 mb-3">
        <label>Exterior kleur </label>
        <Select
          className="select-rado-container"
          classNamePrefix="select-rado"
          options={attributes.colors}
          value={selectedAttributes.color}
          styles={colourStyles}
          onChange={handleColorChange}
        />
      </div>

      <div className="col-xl-4 mb-3">
        <label>Interieur kleur</label>
        <Select
          className="select-rado-container"
          classNamePrefix="select-rado"
          options={attributes.colors}
          value={selectedAttributes.innerColor}
          styles={colourStyles}
          onChange={handleInnerColorChange}
        />
      </div>

      <div className="col-xl-4 mb-3">
        <div className="form-group">
          <label>Prijs </label>
          <input
            type="text"
            className="form-control"
            value={selectedAttributes.price}
            onChange={handlePriceChange}
          />
        </div>
      </div>
      <div className="col-xl-4 mb-3">
        <div className="form-group">
          <label>Kilometerstand</label>
          <input
            type="text"
            className="form-control"
            value={selectedAttributes.kilometer}
            onChange={handleKilometerChange}
          />
        </div>
      </div>
      <div className="col-xl-4 mb-3">
        <div className="form-group">
          <label>Bouwjaar </label>
          <input
            type="text"
            className="form-control"
            value={selectedAttributes.year}
            onChange={handleYearChange}
          />
        </div>
      </div>
      <div className="col-xl-4 mb-3">
        <div className="form-group">
          <label>Motorinhoud</label>
          <input
            type="text"
            className="form-control"
            value={selectedAttributes.cylinderCapacity}
            onChange={handleCylinderCapacityChange}
          />
        </div>
      </div>
      <div className="col-xl-4 mb-3">
        <div className="form-group">
          <label>Zetel </label>{" "}
          <input
            type="text"
            className="form-control"
            value={selectedAttributes.seats}
            onChange={handleSeatsChange}
          />
        </div>
      </div>
      <div className="col-xl-4 mb-3">
        <div className="form-group">
          <label>PK </label>
          <input
            type="text"
            className="form-control"
            value={selectedAttributes.PK}
            onChange={handlePKChange}
          />
        </div>
      </div>
    </div>
  );
};
export default CarAttributes;
