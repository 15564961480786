import React, { useEffect, useState } from "react";
import axios from "axios";
import StockList from "./stockList.jsx";

const Stock = () => {
  const [cars, setCars] = useState([]);

  const getCarsList = () => {
    const token=window.localStorage.getItem('token')||null;
    axios.get(process.env.REACT_APP_API_URL+"/car", {
      headers: {
        "Content-Type": "application/json",
            "Authorization": "Bearer " + token 
      }
    }).then(function(response) {
      var car=  response.data.map(car=>{ car.imageUrls.sort((a, b) =>a.localeCompare(b)); return car;});
     
      setCars(car);
    });
  };
  useEffect(() => {
    getCarsList();
  }, []);
  
  const onSoldChecked = (index, value) => {
    let car = cars[index];
    const params = {
      sold: value,
      id: car.id,
    };
    const token=window.localStorage.getItem('token')||null;
    axios
      .put(process.env.REACT_APP_API_URL+"/car/setSold", params
      , {
        headers: {
          "Content-Type": "application/json",
              "Authorization": "Bearer " + token 
        }
      })
      .then(function(response) {
        let newArr = [...cars];
        newArr[index].sold = value;
        setCars(newArr);
      });
  };
  const onPublishChecked = (index, value) => {
    let car = cars[index];
    const params = {
      published: value,
      id: car.id,
    };
    const token=window.localStorage.getItem('token')||null;
    axios
      .put(process.env.REACT_APP_API_URL+"/car/setPublished", params
      , {
        headers: {
          "Content-Type": "application/json",
              "Authorization": "Bearer " + token 
        }
      })
      .then(function(response) {
        let newArr = [...cars];
        newArr[index].published = value;
        newArr[index].publishDate = response.data.publishDate;
        setCars(newArr);
      });
  };
  const onDraftChecked = (index, value) => {
    let car = cars[index];
    const params = {
      draft: value,
      id: car.id,
    };
    const token=window.localStorage.getItem('token')||null;
    axios
      .put(process.env.REACT_APP_API_URL+"/car/SetDraft", params, {
        headers: {
          "Content-Type": "application/json",
              "Authorization": "Bearer " + token 
        }
      })
      .then(function(response) {
        let newArr = [...cars];
        newArr[index].draft = value;
        setCars(newArr);
      });
  };
  const onFeaturedChecked = (index, value) => {
    let car = cars[index];
    const params = {
      special: value,
      id: car.id,
    };
    const token=window.localStorage.getItem('token')||null;

    axios
      .put(process.env.REACT_APP_API_URL+"/car/setSpecial", params, {
        headers: {
          "Content-Type": "application/json",
              "Authorization": "Bearer " + token 
        }
      })
      .then(function(response) {
        let newArr = [...cars];
        newArr[index].special = value;
        setCars(newArr);
      });
  };
  const EditCar = (id) => {};
  const DeleteCar=(id)=>{
    const token=window.localStorage.getItem('token')||null;
  axios.delete(process.env.REACT_APP_API_URL+"/car/"+id, {
        headers: {
            "Authorization": "Bearer " + token,
        }
      }).then(function(response) {
        getCarsList();
    });
}
  return (
    <StockList
      cars={cars}
      onPublishChecked={onPublishChecked}
      onDraftChecked={onDraftChecked}
      onFeaturedChecked={onFeaturedChecked}
      onSoldChecked={onSoldChecked}
      EditCar={EditCar}
      DeleteCar={DeleteCar}
    />
  );
};
export default Stock;
