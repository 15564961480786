import React from "react";
import EditIcon from "../../../../assets/image/icons/edit-pen.svg";
import DeleteIcon from "../../../../assets/image/icons/delete.svg";
import {Link} from "react-router-dom";
const oddRow = (value) => {
  if (value % 2 == 0) return false;
  return true;
};
const StockList = ({cars,DeleteCar,EditCar,onPublishChecked,onDraftChecked,onFeaturedChecked,onSoldChecked}) => {
  const convertToShortDate=(date)=>{
    const newDate =new Date(date);
    const options = {
  weekday:'long',year:'numeric',month:'long',day:'numeric'
    }
    return newDate.toLocaleDateString("nl-BE",options).toUpperCase();
  }
  
  
  return (
    <div className="container car-list">
      <table className="car-list-table">
        <thead>
          <tr className="car-list-header">
            <th className="pl-2 pr-2">Titel</th>
            <th className="pl-2 pr-2">Foto</th>
            <th className="pl-2 pr-2">Prijs</th>
            <th className="pl-2 pr-2">Bouwjaar</th>
            <th className="pl-2 pr-2">Kilometerstand</th>
            <th className="pr-2">Brandstof</th>
            <th className="pl-2 pr-2">Euronorm</th>
            <th className="pl-2 pr-2">Verkocht</th>
            <th className="pl-2 pr-2">Speciaal</th>
            <th className="pl-2 pr-2">Draft</th>
            <th className="pl-2 pr-2">Publiceren</th>
            <th className="pl-2 pr-2">Publicatie Datum</th>
            <th className="pl-2 pr-2"></th>
            <th className="pl-2 pr-2"></th>
          </tr>
        </thead>
        <tbody>
          {cars.map((car, index) => {
            return (
              <tr className={oddRow(index) ? "odd-row" : ""}key={index}>
                <td className="pl-2 pr-2 text-wrap"style={{color:'blue',width:'12%'}}>{car.title}</td>
                <td className="pl-2 pr-2">
                  <img
                    src={car.imageUrls[0]}
                    style={{ width: "100px" }}
                  />
                </td>
                <td className="pl-2 pr-2"style={{color:'red'}}>{car.price}</td>
                <td className="pl-2 pr-2">{car.year}</td>
                <td className="pl-2 pr-2">{car.kilometer}</td>
                <td className="pl-2 pr-2">{car.fuel}</td>
                <td className="pl-2 pr-2">{car.euroNorm}</td>
                <td className="pl-2 pr-2">
                  <input type="checkbox" checked={car.sold} onChange={(e) => onSoldChecked(index, e.target.checked)}/>
                </td>
               
                <td className="pl-2 pr-2">
                    <input type="checkbox" checked={car.special} onChange={(e) => onFeaturedChecked(index, e.target.checked)}/>
                </td>
                <td className="pl-2 pr-2">
                  <input type="checkbox" checked={car.draft} onChange={(e) => onDraftChecked(index, e.target.checked)}/>
                </td> 
               
                <td className="pl-2 pr-2">
                  <input type="checkbox" checked={car.published} onChange={(e) => onPublishChecked(index, e.target.checked)}/>
                </td>
               
                <td className="pl-2 pr-2 text-wrap">
                  {car.published? `${convertToShortDate(car.publishDate)}\nGepubliceerd`:''}
                </td>
                <td className="pl-1 pr-1">
                <Link to={`/Edit/${car.id}`}><img src={EditIcon} /></Link>
                 
                </td>
                <td className="pl-1 pr-1">
                <img src={DeleteIcon} onClick={()=>DeleteCar(car.id)}/>
                 
                </td> 
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>  
  );
};
export default StockList;
