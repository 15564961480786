import React from "react";
import { render } from "react-dom";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import ErrorMessage from "../../../common/ErrorMessage.jsx";
import axios from "axios";
const onSubmit = async (values) => {
  var user = {
    email: values.email,
    password: values.Password,
  };

  await axios
    .post(process.env.REACT_APP_API_URL+"/user/login", user)
    .then(function(response) {
    });
};

const LoginForm = ({login}) => {
  return (
    <div>
      <h3 className="text-center text-info">Login</h3>
      <FinalForm
        onSubmit={login}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.username = "Required";
          }
          if (!values.password) {
            errors.password = "Required";
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Field name="email">
              {({ input, meta }) => (
                <div className="form-group">
                  <label forhtml="email" className="text-info">
                    Email:
                  </label>
                  <br />

                  <input
                    {...input}
                    type="text"
                    placeholder="Email"
                    className="form-control"
                  />

                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>
            <Field name="password">
              {({ input, meta }) => (
                <div className="form-group">
                  <label forhtml="password" className="text-info">
                    Password
                  </label>
                  <input
                    {...input}
                    type="password"
                    placeholder="Password"
                    className="form-control"
                  />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>
            <div className="buttons">
              <button
                type="submit"
                disabled={submitting}
                className="btn btn-info btn-md"
              >
                Submit
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default LoginForm;
