import React, { useState,useContext} from "react";
import { combineValidators, isRequired } from "revalidate";
import { Form as FinalForm, Field } from "react-final-form";

import axios from "axios";
import LoginForm from "./LoginForm";
const Login=({login})=>{
  const [email, setemail] = useState("administrator@localhost");
  const [password, setpassword] = useState("Administrator1!");


  const validate = combineValidators({
    email: isRequired("Email"),
    password: isRequired("Password"),
  });
  const  handeEmailInputChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setemail(value);
  };
  const handePasswordInputChange = (e) => {
    e.preventDefault();
    setpassword(e.target.value)
  };

  const  handleSubmit = (values) => {
    var s = {
      email: "administrator@localhost",
      password: "Administrator1!",
    };
    var user = {
      email: values.email,
      password: values.password,
    };
    axios
      .post(process.env.REACT_APP_API_URL+"/user/login", user)
      .then(function(response) {
        window.localStorage.setItem('token', response.data.token);
        login(response.data.displayName);
      });
  };
    return (
      <div className="login-form">
        <h3 className="text-center text-white pt-5">Login</h3>
        <div className="container">
          <div
            id="login-row"
            className="row justify-content-center align-items-center"
          >
            <div id="login-column" className="col-md-6">
              <div id="login-box" className="col-md-12">
                <LoginForm
                  login={handleSubmit}
                  handeEmailInputChange={handeEmailInputChange}
                  email={email}
                  handePasswordInputChange={handePasswordInputChange}
                  password={password}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Login;
