import React from "react";
import CarForm from "./carForm.jsx";
import {
    
    useParams
  } from "react-router-dom";
const EditCar=()=>{
  console.log("Edit");
    let { id } = useParams();
    return<CarForm carId={id}type="Edit"/>
}
export default EditCar;