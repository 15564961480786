import React, { useContext, createContext, useState } from 'react';
import logo from './logo.svg';

import './assets/css/font/font.css';
import Admin from './containers/Admin/index.jsx';
import Dashboard from './containers/Admin/Dashboard/index.jsx';
import Login from './containers/Admin/Login/index.jsx';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

function App() {


  
  return (
  <ProvideAuth>
  <Router>
    <div>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <PrivateRoute path="/">
          <Dashboard/>
        </PrivateRoute>
      </Switch>
    </div>
  </Router>
</ProvideAuth>
  );
}
const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};
const authContext = createContext();

const ProvideAuth=({ children })=> {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

const useAuth=()=> {
  return useContext(authContext);
}
const useProvideAuth=()=> {
  const [user, setUser] = useState(null);

  const signin = cb => {
    return fakeAuth.signin(() => {
      setUser("user");
      cb();
    });
  };

  const signout = cb => {
    return fakeAuth.signout(() => {
      setUser(null);
      cb();
    });
  };

  return {
    user,
    signin,
    signout
  };
}
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
function LoginPage() {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  let { from } = location.state || { from: { pathname: "/" } };
  let login = (dispalName) => {
    auth.signin(() => {
      history.replace(from);
    });
  };

  return (
    <Login login={login}/>
  );
}
export default App;
