import React from "react";

const CarFeatures = ({
  features,
  selectedFeatures,
  updateSelectedFeatures,
}) => {
  return (
    <div className="row">
      {features.map((feature) => (
        <div className="col-xl-3">
          <div
            className="form-group form-check"
            onClick={() => updateSelectedFeatures(feature.value)}
          >
            <input
              type="checkbox"
              className="form-check-input"
              checked={selectedFeatures.includes(feature.value)}
            />
            <label className="feature-label ">{feature.label}</label>
          </div>
        </div>
      ))}
    </div>
  );
};
export default CarFeatures;
