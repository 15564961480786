import React from "react";
import DeleteIcon from "../../../../assets/image/icons/delete.svg";
import ViewIcon from "../../../../assets/image/icons/eye.png";
import { Link } from "react-router-dom";
const oddRow = (value) => {
  if (value % 2 == 0) return false;
  return true;
};
const SellCarList = ({cars,DeleteCar,onDraftChecked}) => {
  const convertToShortDate=(date)=>{
    const newDate =new Date(date);
    const options = {
  weekday:'long',year:'numeric',month:'long',day:'numeric'
    }
    return newDate.toLocaleDateString("nl-BE",options).toUpperCase();
  }
  
  
  return (
    <div className="container car-list">
      <table className="car-list-table">
        <thead>
          <tr className="car-list-header">
            <th className="pl-2 pr-2">Title</th>
            <th className="pl-2 pr-2">foto</th>
            <th className="pl-2 pr-2">Bouwjaar</th>
            <th className="pl-2 pr-2">Kilometerstand</th>
            <th className="pr-2">Brandstof</th>
            <th className="pr-2">Gear</th>
            <th className="pl-2 pr-2">Euronorm</th>
            <th className="pl-2 pr-2">Prijs</th>
            <th className="pl-2 pr-2">Ontvangen datum</th>
            <th className="pl-2 pr-2">Draft</th>
            <th className="pl-2 pr-2"></th>
            <th className="pl-2 pr-2"></th>
          </tr>
        </thead>
        <tbody>
          {cars.map((car, index) => {
            return (
              <tr className={car.visited ? "" : "odd-row"}key={index}>
                <td className="pl-2 pr-2 text-wrap"style={{color:'blue',width:'12%'}}>{car.title}</td>
                <td className="pl-2 pr-2">
                  <img
                    src={car.imageUrls[0]}
                    style={{ width: "100px" }}
                  />
                </td>
                <td className="pl-2 pr-2"style={{color:'red'}}>{car.year}</td>
                
                <td className="pl-2 pr-2">{car.kilometer}</td>
                <td className="pl-2 pr-2">{car.fuel}</td>
                <td className="pl-2 pr-2">{car.gear}</td>
                <td className="pl-2 pr-2">{car.euroNorm}</td>
                <td className="pl-2 pr-2">{car.price}</td>
               
                <td className="pl-2 pr-2  text-wrap">
                  {convertToShortDate(car.receivedDate)}
                </td>
                <td className="pl-2 pr-2 border-right">
                  <input type="checkbox" checked={car.draft} onChange={(e) => onDraftChecked(index, e.target.checked)}/>
                </td> 
                <td className="pl-1 pr-1">
                <img src={DeleteIcon} onClick={()=>DeleteCar(car.id)}/>
                </td> 
               
                <td className="pl-1 pr-1">
                <Link to={`/Tekoop-auto/${car.id}`}><img src={ViewIcon} /></Link>
                 
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>  
  );
};
export default SellCarList;