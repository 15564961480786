import React from "react";
import PhotoUploadDropzone from "../../../../common/photoUploadDropzone.jsx";
const CarPhoto = ({ getCarImages,imageUrls,removeImages }) => {
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="row-content">
          <label className="w-100 content-title">FOTO'S</label>

          <div className="row">
            <PhotoUploadDropzone  dropClassName={'dropzone-admin'} removeImages={removeImages} imageUrls={imageUrls}getCarImages={getCarImages}  dragText={"Sleep uw afbeeldingen hierheen, of klik om bestanden te selecteren"}/>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CarPhoto;
